/* eslint-disable max-len */
import { useRouter } from "next/navigation";
import { isMobile } from "react-device-detect";
import styles from "./FormV2Success.module.scss";

const FormV2Success = ({ isFailed = false }) => {
   // debugger;
   const router = useRouter();

   return (
      <div className={styles.container}>
         <img
            style={{ marginBottom: "16px" }}
            width={60}
            height={60}
            src={
               isFailed
                  ? "/assets/img/construction-page/submit-failed.png"
                  : "/assets/img/construction-page/submit-success.png"
            }
            alt="icon-check"
         />
         <div className={styles.title}>{isFailed ? "Chưa thể đăng kí tư vấn" : "Đăng kí tư vấn thành công"}</div>
         <div
            className={styles.message}
            dangerouslySetInnerHTML={{
               __html: isFailed
                  ? `Hệ thống của Space T hiện đang quá tải. Bạn vui lòng thử <br /> lại sau ít phút nữa nhé. Nếu cần hỗ trợ, bạn có thể liên hệ  <br /> đến <span class="${styles.phoneNumber}">084-884-7938</span>`
                  : isMobile
                  ? "Cảm ơn bạn đã đăng kí. Space T sẽ liên hệ <br /> bạn trong thời gian sớm nhất"
                  : "Cảm ơn bạn đã đăng kí. <br /> Space T sẽ liên hệ bạn trong thời gian sớm nhất",
            }}
         />
         <div className={styles.divider}></div>
         <button
            className={styles.button}
            onClick={() => {
               router.refresh();
            }}
         >
            Trở về
         </button>
      </div>
   );
};

export default FormV2Success;
