import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useRouter } from "next/router";
import store from "state";
import { toggleModalSuccess } from "state/global/actions";
import { ModelError, TModelError } from "@Models/model";
import { apiDomain } from "@Models/__variables";
import ButtonSubmit from "@Components/button/submit-btn";
import yup from "../../../../core/yupPatern";
import styles from "./Form.module.scss";

export enum EName {
   name = "hotenkhachhang",
   phone = "dienthoaikhachhang",
   emailkhachhang = "emailkhachhang",
}

export interface IFormInputs {
   [EName.name]: string;
   [EName.phone]: string;
   [EName.emailkhachhang]: string;
}

const schema = yup.object().shape({
   [EName.name]: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(2, "Tối thiểu 2 ký tự")
      .max(24, "Tối đa 24 ký tự"),
   [EName.phone]: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(10, "Số điện thoại phải có 10 chữ số")
      .max(10, "Số điện thoại phải có 10 chữ số"),
   [EName.emailkhachhang]: yup.string().trim().email("Địa chỉ email không hợp lệ"),
});

type Props = {
   className?: string;
   fromBlogDetail?: boolean;
   showTag?: boolean;
   styleBtn?: React.CSSProperties;
};

enum SubmitStatus {
   SUCCESS = "success",
   FAILED = "failed",
   IDLE = "idle",
}

/**
 * Form đăng ký tư vấn nhưng hiện ở render lần đầu tiên, không hiện trên popup
 */
const Form: React.FC<Props> = ({ className, fromBlogDetail, showTag = false, styleBtn }) => {
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [errorsMes, setErrorsMes] = useState<ModelError["errors"]>();
   const router = useRouter();
   const isBlogDetailPage = /^\/blog\/[^\/]+\/[^\/]+$/.test(router.asPath);
   const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.IDLE);

   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
   });

   const onSubmitForm = async (formData: IFormInputs) => {
      try {
         setIsSubmitting(true);
         const response = await fetch(`${apiDomain}/www/consulting/push-monday`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               "X-Requested-With": "XMLHttpRequest",
            },
            body: JSON.stringify(formData),
         });

         if (!response.ok) {
            setSubmitStatus(SubmitStatus.FAILED);
            throw new ModelError((await response.json()) as TModelError);
         }

         if (!isBlogDetailPage) {
            store.dispatch(
               toggleModalSuccess({
                  visible: true,
                  title: "Đăng kí tư vấn thành công",
                  description:
                     "Đơn đăng kí tư vấn của bạn đã gửi thành công. Space T sẽ liên hệ tới bạn trong thời gian sớm nhất.",
               })
            );
         } else {
            setSubmitStatus(SubmitStatus.SUCCESS);
         }

         setErrorsMes(undefined);
         reset();
      } catch (err) {
         setErrorsMes(new ModelError(err).errors);
      } finally {
         setIsSubmitting(false);
      }
   };

   return (
      <div className={className ? styles[className] : ""}>
         <h2
            className={classNames({
               [styles["title"]]: !fromBlogDetail,
               [styles["title-2"]]: !fromBlogDetail,
            })}
         >
            Thiết kế nội thất, để Space T giúp bạn
         </h2>

         <form
            className={
               className === "form-wrap-vertical" || className === "form-wrap-vertical-2"
                  ? styles["form-vertical"]
                  : styles["form"]
            }
            onSubmit={handleSubmit(onSubmitForm)}
         >
            {/* Trường Họ và Tên */}
            <div
               className={classNames(styles["form-item"], {
                  [styles["error"]]: errors[EName.name],
               })}
            >
               <label className={styles["label"]}>
                  <span className="text-danger me-1">*</span>
                  Họ và tên
               </label>
               <input
                  type="text"
                  className={classNames(styles["form-control"], "form-control")}
                  placeholder="Điền tên đầy đủ của bạn"
                  {...register(EName.name)}
               />
               {errors[EName.name] && <p className={styles["text-error"]}>{errors[EName.name]?.message}</p>}
            </div>

            {/* Trường Số điện thoại */}
            <div
               className={classNames(styles["form-item"], {
                  [styles["error"]]: errors[EName.phone],
               })}
            >
               <label className={styles["label"]}>
                  <span className="text-danger me-1">*</span>
                  Số điện thoại
               </label>
               <input
                  type="tel"
                  inputMode="tel"
                  className={classNames(styles["form-control"], "form-control")}
                  placeholder="Nhập số điện thoại"
                  {...register(EName.phone)}
               />
               {errors[EName.phone] && <p className={styles["text-error"]}>{errors[EName.phone]?.message}</p>}
            </div>

            {/* Trường Email */}
            <div
               className={classNames(styles["form-item"], {
                  [styles["error"]]: errors[EName.emailkhachhang],
               })}
            >
               <label className={styles["label"]}>Email</label>
               <input
                  type="text"
                  className={classNames(styles["form-control"], "form-control")}
                  placeholder="Nhập địa chỉ email"
                  {...register(EName.emailkhachhang)}
               />
               {errors[EName.emailkhachhang] && (
                  <p className={styles["text-error"]}>{errors[EName.emailkhachhang]?.message}</p>
               )}
            </div>

            {/* Thông báo thành công hoặc thất bại */}
            {isBlogDetailPage && submitStatus === SubmitStatus.SUCCESS && (
               <div
                  style={{
                     borderRadius: 8,
                     padding: "12px",
                     background: "#F0FFEA",
                     marginBottom: "-8px",
                  }}
                  className="d-flex align-items-start gap-2"
               >
                  <img style={{ marginTop: "4px" }} width={18} height={18} src="/assets/img/blog/check.png" />
                  <div>Đơn đăng kí tư vấn gửi thành công. Space T sẽ liên hệ tới bạn trong thời gian sớm nhất.</div>
               </div>
            )}

            {isBlogDetailPage && submitStatus === SubmitStatus.FAILED && (
               <div
                  style={{
                     borderRadius: 8,
                     padding: "12px",
                     background: "#FFE6E8",
                     marginBottom: "-8px",
                  }}
                  className="d-flex align-items-start gap-2"
               >
                  <img style={{ marginTop: "4px" }} width={18} height={18} src="/assets/img/blog/err-check.png" />
                  <div>
                     Hệ thống của Space T hiện đang quá tải. Bạn vui lòng thử lại sau ít phút nữa nhé. Nếu cần hỗ trợ,
                     bạn có thể liên hệ đến <span style={{ color: "#FF6347" }}>084-884-7938</span>
                  </div>
               </div>
            )}

            {/* Nút Submit */}
            <div className={classNames(styles["form-item"])}>
               <ButtonSubmit
                  style={styleBtn}
                  type="submit"
                  showTag={showTag}
                  tagText="MIỄN PHÍ"
                  disabled={isSubmitting}
               >
                  Đăng kí tư vấn
               </ButtonSubmit>
            </div>
         </form>
      </div>
   );
};

export default Form;
