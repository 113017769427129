interface IMenu {
   title: string;
   icon: string;
   iconLg: string;
   path: string;
}

export const MENUS: IMenu[] = [
   {
      title: "Hình ảnh nội thất",
      icon: "/assets/img/menu/library.png",
      iconLg: "/assets/img/menu/library.png",
      path: "/thu-vien-anh",
   },
   {
      title: "Dự án hoàn thiện",
      icon: "/assets/img/menu/project-finish.png",
      iconLg: "/assets/img/menu/project-finish.png",
      path: "/thu-vien-thiet-ke",
   },
   {
      title: "Dự toán miễn phí",
      icon: "/assets/img/menu/calculate.png",
      iconLg: "/assets/img/menu/calculate.png",
      path: "/bao-gia",
   },
   {
      title: "Thiết kế 2D/3D miễn phí",
      icon: "/assets/img/menu/2d-3d.png",
      iconLg: "/assets/img/menu/2d-3d.png",
      path: "/thiet-ke-2d-3d",
   },
   {
      title: "Kiến thức",
      icon: "/assets/img/menu/kien_thuc.png",
      iconLg: "/assets/img/menu/kien_thuc.png",
      path: "/blog",
   },
];
