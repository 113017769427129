import React, { PropsWithChildren, ReactNode } from "react";
import { isMobile } from "react-device-detect";
import Drawer, { DrawerHeader, IDrawerProps } from "@Components/drawer";
import Modal, { IModalProps, ModalHeader } from "./index";

export interface IModalMainProps extends IDrawerProps, IModalProps {
   className?: string;
   maxWidth?: string;
   open?: boolean;
   onClose?: () => void;
   isFromPopup?: boolean;
}

function Layout({ open, maxWidth, fullHeight, onClose, children, isFromPopup, ...props }: any) {
   if (!isMobile || isFromPopup) {
      return (
         <Modal open={Boolean(open)} maxWidth={maxWidth} onClose={onClose} isFromPopup={isFromPopup} {...props}>
            {children}
         </Modal>
      );
   }
   return (
      <Drawer anchor="bottom" open={Boolean(open)} fullHeight={fullHeight} onClose={onClose} {...props}>
         {children}
      </Drawer>
   );
}

export const ModalMainHeader = ({
   children,
   ...props
}: PropsWithChildren<{
   iconClose?: ReactNode;
   title?: any;
   backLabel?: any;
   onBack?: () => void;
   onClose?: () => void;
}>) => {
   return isMobile ? (
      <DrawerHeader {...props}>{children}</DrawerHeader>
   ) : (
      <ModalHeader {...props}>{children}</ModalHeader>
   );
};

export const ModalMain: React.FC<PropsWithChildren<IModalMainProps>> = ({
   open,
   onClose,
   children,
   isFromPopup,
   ...props
}) => {
   return (
      <Layout open={open} onClose={onClose} isFromPopup={isFromPopup} {...props}>
         {children}
      </Layout>
   );
};

export default ModalMain;
