/* eslint-disable max-len */
import classNames from "classnames";
import { useEffect, useState } from "react";
import Loadingv2 from "@Components/Loading/v2";
import { apiDomain } from "models/__variables";
import Model, { ModelError, TPayload } from "models/model";

export enum ETabType {
   province = "province",
   district = "district",
   ward = "ward",
}

export interface IAddressItem {
   id?: number;
   name?: string;
   parent_id?: number | null;
}
export type TForm = {
   province?: IAddressItem;
   district?: IAddressItem;
   ward?: IAddressItem;
};

export default function DropdownSelect({
   className,
   placeholder,
   initValue,
   hasAsterisk,
   onChange,
   onActiveChange,
   isHideWard,
   ...props
}: {
   className?: string;
   placeholder?: string;
   initValue?: TForm;
   hasAsterisk?: boolean;
   onChange?: (form: TForm) => void;
   onActiveChange?: (active: boolean) => void;
   isHideWard?: boolean;
}) {
   const [type, setType] = useState(ETabType.province);
   const [show, setShow] = useState(false);
   const [list, setList] = useState<{ [key in ETabType]?: IAddressItem[] }>({});
   const [loading, setLoading] = useState(true);
   const [form, setForm] = useState<TForm>();

   useEffect(() => {
      onActiveChange?.(show); // Gọi callback mỗi khi show thay đổi
   }, [show, onActiveChange]);

   useEffect(() => {
      if (initValue) {
         setForm(initValue);
      }
   }, [initValue]);

   useEffect(() => {
      function handleClickOutSide(e) {
         const currentEle = document.getElementById("select-address");
         if (currentEle && !currentEle.contains(e.target)) {
            setShow(false);
         }
      }
      document.addEventListener("mousedown", handleClickOutSide);
      return () => {
         document.removeEventListener("mousedown", handleClickOutSide);
      };
   }, []);

   useEffect(() => {
      fetchListData({ type: ETabType.province });
   }, []);

   function fetchListData(params?: { type: ETabType; id?: number }) {
      if (!params) return;
      setLoading(true);
      Model.fetchData({
         url: `${apiDomain}/integrate/ghtk/delivered-address`,
         method: "GET",
         isRequiredAuthorization: false,
         headers: {
            ...(params?.id ? { id: params.id.toString() } : undefined),
         },
      }).then(async (res) => {
         if (res instanceof ModelError) {
            setLoading(false);
            return;
         }
         const payload = (await res.json()) as TPayload<{
            data: {
               id: number;
               name: string;
               parent_id: number | null;
            }[];
         }>;
         setLoading(false);
         setList({ ...list, [params.type]: payload.data.data });
      });
   }

   function handleSelect(type: ETabType, value: IAddressItem) {
      let newForm: { [key in ETabType]?: IAddressItem } = {};

      switch (type) {
         case ETabType.province:
            newForm = {
               [ETabType.province]: value,
            };
            // switch to tab
            setType(ETabType.district);
            fetchListData({ type: ETabType.district, id: value.id });
            break;
         case ETabType.district:
            newForm = {
               [ETabType.province]: form?.province,
               [ETabType.district]: value,
            };
            // switch to tab or close if hiding ward
            if (isHideWard) {
               setShow(false);
            } else {
               setType(ETabType.ward);
               fetchListData({ type: ETabType.ward, id: value.id });
            }
            break;
         case ETabType.ward:
            newForm = {
               [ETabType.province]: form?.province,
               [ETabType.district]: form?.district,
               [ETabType.ward]: value,
            };
            // done to close
            setShow(false);
            break;
         default:
            break;
      }
      setForm(newForm);
      onChange?.(newForm);
   }

   function getInputValue() {
      const strs: string[] = [];
      if (form?.province) strs.push(form?.province?.name || "");
      if (form?.district) strs.push(form?.district?.name || "");
      if (form?.ward) strs.push(form?.ward?.name || "");
      return strs.join(", ");
   }

   return (
      <div className={classNames("DropdownSelect", className)} id="select-address">
         <div className={classNames(hasAsterisk ? "wrap-input-1" : "wrap-input", show ? "active" : "")}>
            <input
               type="text"
               className="input"
               placeholder={placeholder}
               value={getInputValue()}
               onFocus={() => setShow(true)}
               {...props}
            />
            <svg
               onClick={() => setShow((prev) => !prev)}
               xmlns="http://www.w3.org/2000/svg"
               width="10"
               height="8"
               viewBox="0 0 10 8"
               fill="none"
            >
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.6945 5.30915C9.89764 5.50823 10 5.76687 10 6.04321C10 6.21751 9.95972 6.396 9.86414 6.561C9.77532 6.71783 9.64905 6.8453 9.49358 6.93548C9.32405 7.0398 9.13878 7.08235 8.95987 7.08235C8.68552 7.08235 8.43951 6.97938 8.2451 6.80159L8.23124 6.78891L4.99967 3.45008L1.76772 6.78436L1.75903 6.79265C1.56024 6.98236 1.30792 7.08235 1.0347 7.08235C0.851059 7.08235 0.670691 7.03602 0.507375 6.93916C0.347491 6.84788 0.217067 6.71571 0.129294 6.54949C0.0384312 6.38772 0 6.21347 0 6.04321C0 5.77297 0.0961781 5.51496 0.296294 5.31304L4.24384 1.24488C4.33577 1.14701 4.45011 1.05962 4.58926 1.00012C4.72502 0.942077 4.86485 0.917969 5 0.917969C5.13694 0.917969 5.27414 0.944039 5.40531 1.00012C5.54445 1.05961 5.65879 1.147 5.75072 1.24487L9.6945 5.30915Z"
                  fill="#9F9F9F"
               />
            </svg>
         </div>
         <div className="DropdownSelect__select" style={{ display: show ? "block" : "none" }}>
            <div className="tabs">
               <div
                  className={classNames("tabs-name ", type === ETabType.province ? "active" : "")}
                  onClick={() => {
                     if (list[ETabType.province]) setType(ETabType.province);
                  }}
               >
                  {form?.[ETabType.province]?.id ? form[ETabType.province]?.name : "Tỉnh/Thành phố"}
               </div>
               <div
                  className={classNames("tabs-name ", type === ETabType.district ? "active" : "")}
                  onClick={() => {
                     if (list[ETabType.district]) setType(ETabType.district);
                  }}
               >
                  {form?.[ETabType.district]?.id ? form[ETabType.district]?.name : "Quận/Huyện"}
               </div>
               {!isHideWard && (
                  <div
                     className={classNames("tabs-name ", type === ETabType.ward ? "active" : "")}
                     onClick={() => {
                        if (list[ETabType.ward]) setType(ETabType.ward);
                     }}
                  >
                     {form?.[ETabType.ward]?.id ? form[ETabType.ward]?.name : "Phường/Xã"}
                  </div>
               )}
            </div>
            <div className="dropdown-list">
               {loading ? (
                  <div className="w-loading">
                     <Loadingv2 />
                  </div>
               ) : (
                  list[type]?.map((item) => {
                     const isFillValue = form?.[type]?.id;
                     const active = form?.[type]?.id === item.id;
                     return (
                        <div
                           className={classNames(
                              "dropdown-list--item",
                              active ? "active" : "",
                              isFillValue ? "fill" : ""
                           )}
                           key={item.id}
                           onClick={() => handleSelect(type, item)}
                        >
                           {item.name}
                           <div className="right-icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="17"
                                 height="13"
                                 viewBox="0 0 17 13"
                                 fill="none"
                              >
                                 <path d="M1 6L6 11L16 1" stroke="#FF6347" strokeWidth="2" strokeLinecap="round" />
                              </svg>
                           </div>
                        </div>
                     );
                  })
               )}
            </div>
         </div>
      </div>
   );
}
