import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import dynamic from "next/dynamic";
import { ModelError, TModelError } from "@Models/model";
import { apiDomain } from "@Models/__variables";
import DropdownSelect, { TForm } from "@Components/modal/DialogCreateAddress/DropdownSelect";
import Banner, { EBannerSection } from "@Models/banner.model";
import { SubmitStatus } from "@Pages/quy-trinh-ket-noi/index.page";
import ButtonSubmit from "@Components/button/submit-btn";
import useResponsive, { EScreenSize } from "@Hook/useResponsive";
import { setGlobalLoading } from "state/global/actions";
import store from "state";
import yup from "../../../../core/yupPatern";
import styles from "./FormV2.module.scss";
import countries from "./Constant";

const Banners = dynamic(() => import("@Modules/homev2/components/Banners"), {
   loading: () => <></>,
});

// Define the input names explicitly.
export interface IFormInputs {
   hotenkhachhang: string;
   dienthoaikhachhang: string;
   emailkhachhang: string;
   tenduan?: string;
   province?: string;
   district?: string;
}

// Validation schema
const schema = yup.object().shape({
   hotenkhachhang: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(2, "Tối thiểu 2 ký tự")
      .max(24, "Tối đa 24 ký tự"),
   dienthoaikhachhang: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(8, "Số điện thoại không hợp lệ")
      .max(12, "Số điện thoại không hợp lệ"),
   emailkhachhang: yup.string().trim().email("Địa chỉ email không hợp lệ"),
   tenduan: yup.string().trim().required("Thông tin bắt buộc không được để trống"),
});

// Helper to render error messages.
const renderError = (error: string | { code: string | number; message: string }[] | undefined) => {
   if (typeof error === "string") {
      return error;
   }
   if (Array.isArray(error)) {
      return error.map((err, index) => (
         <span key={index}>
            {err.message}
            {index < error.length - 1 ? ", " : ""}
         </span>
      ));
   }
   return null;
};

/**
 * Form đăng ký tư vấn trên popup
 */
const FormV2 = ({
   banners = [],
   isFromPopup,
   isFromBlogDetail,
   isHideWard,
   setIsSubmitStatus,
   showTag = false,
}: {
   banners?: Banner[];
   isFromPopup?: boolean;
   isFromBlogDetail?: boolean;
   isHideWard?: boolean;
   setIsSubmitStatus?: (status: SubmitStatus) => void;
   showTag?: boolean;
}) => {
   const formEndRef = useRef<HTMLDivElement>(null);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [errorsMes, setErrorsMes] = useState<ModelError["errors"]>();
   const [drafInitAddress, setDrafInitAddress] = useState<TForm>();
   const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
   const [selectedCountry, setSelectedCountry] = useState(
      countries.find((country) => country.idd === "+84") || countries[0]
   );
   const [displayedCountries, setDisplayedCountries] = useState(countries);
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const screenSize = useResponsive();
   const isDesktop = screenSize === EScreenSize.DESKTOP;

   const handleCountrySelect = (country) => {
      setSelectedCountry(country);
      setIsCountryDropdownOpen(false);
      setDisplayedCountries(countries);
   };

   const toggleDropdown = () => {
      setIsCountryDropdownOpen(!isCountryDropdownOpen);
      setDisplayedCountries(countries);
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (!isCountryDropdownOpen) return;

         // Chỉ xử lý các ký tự chữ cái
         const key = event.key.toLowerCase();
         if (key.length === 1 && key.match(/[a-z]/)) {
            const filtered = countries.filter((country) => country.name.toLowerCase().startsWith(key));
            if (filtered.length > 0) {
               setDisplayedCountries([...filtered]);
            }
         }
      };

      window.addEventListener("keypress", handleKeyPress);
      return () => {
         window.removeEventListener("keypress", handleKeyPress);
      };
   }, [isCountryDropdownOpen]);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (isCountryDropdownOpen && !event.target.closest(`.${styles["country-code"]}`)) {
            setIsCountryDropdownOpen(false);
            setDisplayedCountries(countries);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
   }, [isCountryDropdownOpen]);

   const {
      register,
      handleSubmit,
      setValue: setFieldValue,
      setError: setFormErrors,
      formState: { errors },
      reset,
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
   });

   const handleChangeSelectAddress = (form: TForm) => {
      const { province, district } = form || {};
      if (province && district) {
         setFieldValue("tenduan", `${province.name};${district.name}`);
         setFormErrors("tenduan", {});
         return;
      }
   };

   const onSubmitForm = async (formData: IFormInputs) => {
      formData.dienthoaikhachhang = selectedCountry.idd + formData.dienthoaikhachhang;

      try {
         store.dispatch(setGlobalLoading(true));
         setIsSubmitting(true);

         formData.province = formData?.tenduan?.split(";")[0];
         formData.district = formData.tenduan?.split(";")[1];
         delete formData.tenduan;

         const response = await fetch(`${apiDomain}/www/consulting/push-monday`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               "X-Requested-With": "XMLHttpRequest",
            },
            body: JSON.stringify(formData),
         });

         setErrorsMes(undefined);
         reset();
         setDrafInitAddress({});

         if (!response.ok) {
            setIsSubmitStatus?.(SubmitStatus.failed);
            throw new ModelError((await response.json()) as TModelError);
         }

         setIsSubmitStatus?.(SubmitStatus.success);
      } catch (err) {
         setErrorsMes(new ModelError(err).errors);
      } finally {
         store.dispatch(setGlobalLoading(false));
         setIsSubmitting(false);
      }
   };

   return (
      <>
         {isFromBlogDetail ? (
            <div>Thiết kế nội thất, để Space T giúp bạn</div>
         ) : (
            banners?.length > 0 && (
               <Banners
                  isFromPopup={isFromPopup}
                  data={[
                     {
                        mediaUrl: isDesktop
                           ? "/assets/img/construction-page/form2/banner1.png"
                           : "/assets/img/construction-page/form2/banner-mobile.png",
                     } as any,
                  ]}
               />
            )
         )}
         <div className={styles["form-wrap-vertical"]}>
            <form className={styles["form-vertical"]} onSubmit={handleSubmit(onSubmitForm)}>
               {/* Họ và tên field */}
               <div
                  className={classNames(styles["form-item"], {
                     [styles["error"]]: errors.hotenkhachhang || errorsMes?.hotenkhachhang,
                  })}
               >
                  <div className={styles["asterisk"]}>*</div>
                  <input
                     style={{ padding: "0px 16px" }}
                     type="text"
                     placeholder="Họ và tên"
                     {...register("hotenkhachhang")}
                     onKeyDown={(e) => {
                        e.stopPropagation();
                     }}
                  />
                  {(errors.hotenkhachhang || errorsMes?.hotenkhachhang) && (
                     <div className={styles["text-error"]}>
                        {renderError(errors.hotenkhachhang?.message || errorsMes?.hotenkhachhang)}
                     </div>
                  )}
               </div>

               {/* Số điện thoại field */}
               <div className={styles["phone-input-container"]}>
                  <div className={styles["country-code"]} onClick={toggleDropdown}>
                     <img
                        src={selectedCountry.flags.png}
                        alt={selectedCountry.name}
                        className={styles["country-flag"]}
                     />
                     <span>{selectedCountry.idd}</span>
                     <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: isCountryDropdownOpen ? "rotate(180deg)" : "rotate(0deg)" }}
                     >
                        <path
                           d="M1 1L5 5L9 1"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                     {isCountryDropdownOpen && (
                        <div className={styles["country-dropdown"]}>
                           {displayedCountries.map((country, index) => (
                              <div
                                 key={index}
                                 className={styles["country-option"]}
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    handleCountrySelect(country);
                                 }}
                              >
                                 <img src={country.flags.png} alt={country.name} className={styles["country-flag"]} />
                                 <span>
                                    {country.name} ({country.idd})
                                 </span>
                              </div>
                           ))}
                        </div>
                     )}
                  </div>
                  <div className={styles["separator-line"]}></div>
                  <div
                     className={classNames(styles["form-item"], {
                        [styles["error"]]: errors.dienthoaikhachhang || errorsMes?.dienthoaikhachhang,
                     })}
                  >
                     <div className={styles["asterisk"]}>*</div>
                     <input
                        placeholder=" Nhập số điện thoại"
                        {...register("dienthoaikhachhang")}
                        type="number"
                        style={{ border: "none", padding: "0px 16px", height: "44px" }}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                           const input = e.currentTarget;
                           if (input.value.startsWith("0")) {
                              input.value = input.value.replace(/^0+/, "");
                           }
                        }}
                        onKeyDown={(e) => {
                           e.stopPropagation();
                        }}
                     />
                  </div>
               </div>
               {(errors.dienthoaikhachhang || errorsMes?.dienthoaikhachhang) && (
                  <div style={{ marginTop: "-16px" }} className={styles["text-error"]}>
                     {renderError(errors.dienthoaikhachhang?.message || errorsMes?.dienthoaikhachhang)}
                  </div>
               )}

               {/* Email field */}
               <div
                  className={classNames(styles["form-item"], {
                     [styles["error"]]: errors.emailkhachhang || errorsMes?.emailkhachhang,
                  })}
               >
                  <input
                     type="text"
                     placeholder="Nhập email"
                     {...register("emailkhachhang")}
                     style={{ padding: "0px 8px" }}
                     onKeyDown={(e) => {
                        e.stopPropagation();
                     }}
                  />
                  {(errors.emailkhachhang || errorsMes?.emailkhachhang) && (
                     <div className={styles["text-error"]}>
                        {renderError(errors.emailkhachhang?.message || errorsMes?.emailkhachhang)}
                     </div>
                  )}
               </div>

               {/* Tỉnh/Thành phố field */}
               <div className={styles["form-item"]}>
                  <div className={styles["asterisk"]}>*</div>
                  <DropdownSelect
                     {...register("tenduan")}
                     placeholder="Chọn Tỉnh/Thành phố"
                     onChange={handleChangeSelectAddress}
                     initValue={drafInitAddress}
                     hasAsterisk={true}
                     isHideWard={isHideWard}
                     onActiveChange={(isActive) => {
                        if (isActive) {
                           setIsDropdownOpen(true);
                           if (formEndRef.current) {
                              formEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
                           }
                        } else {
                           setIsDropdownOpen(false);
                        }
                     }}
                  />
               </div>
               {(errors.tenduan || errorsMes?.tenduan) && (
                  <div style={{ marginTop: "-16px" }} className={styles["text-error"]}>
                     {renderError(errors.tenduan?.message || errorsMes?.tenduan)}
                  </div>
               )}

               <ButtonSubmit
                  type="submit"
                  showTag={showTag}
                  tagText="MIỄN PHÍ"
                  style={isDropdownOpen ? { marginTop: "300px" } : { marginTop: "0px" }}
                  disabled={isSubmitting}
               >
                  Đăng kí tư vấn
               </ButtonSubmit>
            </form>
         </div>
         {/* Thêm div này để cuộn đến */}
         <div ref={formEndRef} />
      </>
   );
};

export default FormV2;
