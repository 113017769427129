import React from "react";
import { isMobile } from "react-device-detect";
import styles from "./JustThreeStep.module.scss";

const MENUS = [
   {
      title: "Tìm ý tưởng",
      title1: "Tham khảo các gợi ý & mẫu thiết kế nội thất từ chuyên gia",
      icon: "/assets/img/construction-page/TimYTuong.png",
      iconLg: "/assets/img/construction-page/TimYTuong.png",
      icon1: "/assets/img/construction-page/one.png",
      path: "",
   },
   {
      title: "Triển khai thiết kế & thi công",
      title1: "Hoàn thiện bản vẽ thiết kế, tiến hành thi công và lắp đặt nội thất",
      icon: "/assets/img/construction-page/TrienKhai.png",
      iconLg: "/assets/img/construction-page/TrienKhai.png",
      icon1: "/assets/img/construction-page/two.png",
      path: "",
   },

   {
      title: "Nhận bàn giao",
      title1: "An tâm nhận nhà, cam kết bảo hành 1+ năm sau khi bàn giao công trình",
      icon: "/assets/img/construction-page/NhanBanGiao.png",
      iconLg: "/assets/img/construction-page/NhanBanGiao.png",
      icon1: "/assets/img/construction-page/three.png",
      path: "",
   },
];

type Props = {
   handleOpenPopupDangkyTuVan?: () => void;
   isHideTuVanFree?: boolean;
   title?: string;
   menus?: any[];
};

const JustThreeStep: React.FC<Props> = ({
   handleOpenPopupDangkyTuVan,
   isHideTuVanFree,
   title = "Làm nội thất dễ dàng chỉ với 3 bước",
   menus = MENUS,
}) => {
   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["main_title"]}>{title}</h2>
         </div>
         <div className={styles["spacet-partner"]}>
            <div className="row gx-2 gy-2 gy-md-3 gx-md-3">
               {menus.map((item, index) => (
                  <div
                     key={index}
                     className="col-12 col-md-4"
                     style={
                        !isMobile
                           ? {}
                           : {
                                marginTop: "0px",
                                marginBottom: "0px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                             }
                     }
                  >
                     {index !== 0 && isMobile && (
                        <div
                           style={{
                              border: "none",
                              height: "1px",
                              background: "#F0F0F0",
                              margin: "10px 0",
                           }}
                        />
                     )}

                     <div className={styles["container"]}>
                        <div className={styles["item"]}>
                           <img
                              title={item.title || ""}
                              alt={item.title || ""}
                              src={item.iconLg || ""}
                              width="96px"
                              height="96px"
                           />
                           <div className={styles["description"]}>
                              <div className={styles["title-wrapper"]}>
                                 <img
                                    title={item.title || ""}
                                    alt={item.title || ""}
                                    src={item.icon1 || ""}
                                    width="24px"
                                    height="24px"
                                 />
                                 <div className={styles["title"]}>{item.title}</div>
                              </div>
                              <div className={styles["title1"]}>{item.title1}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>

            {/*** button Dang Ky Tu Van */}
            {!isHideTuVanFree && (
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     marginTop: !isMobile ? "0px" : "-10px",
                  }}
               >
                  <button type="submit" className={styles["btn-submit"]} onClick={handleOpenPopupDangkyTuVan}>
                     Đăng kí tư vấn
                     <span className={styles["tag"]}>
                        <span>MIỄN PHÍ</span>
                     </span>
                  </button>
               </div>
            )}
         </div>
      </div>
   );
};

export default JustThreeStep;
