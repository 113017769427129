import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface ButtonProps {
   type?: "button" | "submit" | "reset";
   disabled?: boolean;
   onClick?: () => void;
   children: React.ReactNode;
   className?: string;
   showTag?: boolean;
   tagText?: string;
   style?: React.CSSProperties;
}

const ButtonSubmit: React.FC<ButtonProps> = ({
   type = "button",
   disabled = false,
   onClick,
   children,
   className,
   showTag = false,
   tagText = "",
   style = {},
}) => {
   return (
      <button
         type={type}
         className={classNames(styles["btn-submit"], className)}
         disabled={disabled}
         onClick={onClick}
         style={style}
      >
         {children}
         {showTag && (
            <span className={styles["tag"]}>
               <span>{tagText}</span>
            </span>
         )}
      </button>
   );
};

export default ButtonSubmit;
